import { FC, useEffect, useState } from "react";
import moment from "moment";

interface TimeElapsedProps {
  start: Date;
  end?: Date;
}

export const TimeElapsed: FC<TimeElapsedProps> = ({ start, end }) => {
  const [elapsed, setElapsed] = useState<number>(() => getElapsedTime(start, end));

  useEffect(() => {
    if (!end) {
      const initialDelay = 1000 - (Date.now() - start.getTime()) % 1000;

      let intervalId: NodeJS.Timeout | null = null;
      const timeoutId = setTimeout(() => {
        setElapsed(() => {
          return getElapsedTime(start, end);
        });
        intervalId = setInterval(() => {
          setElapsed(() => {
            return getElapsedTime(start, end);
          });
        }, 1000);

      }, initialDelay);

      return () => {
        clearTimeout(timeoutId);
        if (intervalId !== null) {
          clearInterval(intervalId);
        }
      };
    }
  }, [end, start]);

  return (
    <p className="subtitle">
      Time elapsed{" "}
      {moment.utc(elapsed).format("mm:ss")} {" (mm:ss)"}
    </p>
  );
};

function getElapsedTime(start: Date, end?: Date): number {
  const startTime = start.getTime();
  const endTime = end ? end.getTime() : Date.now();
  return endTime - startTime;
}
