import { FC } from "react";
import { RunDocument } from "../../types/Run";
import { TimeElapsed } from "./TimeElapsed";
import { RunMap } from "./RunMap";
import { DeviceStatus } from "./DeviceStatus";
import "./RunDetail.module.css";
import { WattageChart } from "./WattageChart";
import { LogMessages } from "./LogMessages";
import useWindowWidth from "../../helpers/useWindowWidth";
import { TrashChart } from "./TrashChart";


interface RunDetailProps {
  runDocument: RunDocument;
}

export const RunDetail: FC<RunDetailProps> = ({ runDocument }: RunDetailProps) => {
  const {
    start,
    end,
    cigaretteCount,
    corkCount,
    petCount,
    deviceStatus,
    logs,
    performance
  } = runDocument;

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;

  console.log({runDocument})

  return (
    <div className="container">
      <section className="section">
        <div className="level">
          <div className="level-left">
            <h1 className="title is-3" style={{paddingBottom: "15px"}}>Gruppe 9</h1>
          </div>
          {isMobile ? (
            <div className="box">
              <div className="level-center">
                <TimeElapsed start={start} end={end} />
              </div>
              <div className="level-right">
                <DeviceStatus status={deviceStatus} />
              </div>
            </div>
          ) : (
            <>
              <div className="level-center">
                <TimeElapsed start={start} end={end} />
              </div>
              <div className="level-right">
                <DeviceStatus status={deviceStatus} />
              </div>
            </>
          )}
        </div>
        <div className="columns">
            <div className="column">
              <WattageChart start={start} data={performance} />
            </div>
            <div className="column">
              <TrashChart cigaretteCount={cigaretteCount} corkCount={corkCount} petCount={petCount} />
            </div>
        </div>
        <RunMap mapData={{}} />
        <LogMessages logs={logs} />
      </section>
    </div>
  );
};
