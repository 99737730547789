import { collection, onSnapshot, orderBy, query } from '@firebase/firestore';
import { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import { RunDocumentWithId } from '../../types/Run';
import moment from "moment";


function RunListPage() {
  const [runs, setRuns] = useState<RunDocumentWithId[]>([]);
  const q = query(collection(db, "runs"), orderBy("start", "desc"));


  useEffect(() => {
    return onSnapshot(q, (snapshot) => {
      setRuns(
        snapshot.docs.map((doc) => {
          const runData = doc.data();
          var run = {
            id: doc.id,
            ...runData,
            start: runData.start.toDate(),
          } as RunDocumentWithId
          return run;
        })
      );
    });
  }, [runs, q]);

  return (
    <div className="container">
      <section className="section">
        <h1 className="title">Past Runs</h1>
        <div className="columns is-multiline">
          {runs.map((run) => (
            <div key={run.id} className="column is-one-third">
              <div className="card">
                <div className="card-content">
                  <p className="title is-4">{moment(run.start).format("D. MMMM, HH:mm:ss")}</p>
                </div>
                <footer className="card-footer">
                  <Link to={`/${run.id}`} className="card-footer-item">
                    View Details
                  </Link>
                </footer>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export { RunListPage };
