import { FC } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop, faPlay, faCog, faExclamationTriangle, IconDefinition } from '@fortawesome/free-solid-svg-icons';


interface DeviceStatusProps {
  status: string;
}

const getDeviceStatusIconAndColor = (status: string): {icon: IconDefinition, color: string} => {
  switch (status) {
    case 'Initializing':
      return { icon: faCog, color: 'orange' };
    case 'Running':
      return { icon: faPlay, color: 'green' };
    case 'Reestablish':
      return { icon: faExclamationTriangle, color: 'yellow' };
    case 'Stopped':
    default:
      return { icon: faStop, color: 'red' };
  }
};


export const DeviceStatus: FC<DeviceStatusProps> = ({ status }) => {
  const { icon, color } = getDeviceStatusIconAndColor(status);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p className="subtitle">
        Status:<FontAwesomeIcon icon={icon} size="2x" style={{ color: color, marginRight: '10px', marginLeft: '10px' }} />
        <span>{status}</span>
      </p>
    </div>
  );
};
