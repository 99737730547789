import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBbC7a6RXUfj0iV36gZszXUlnKs_YLJZWY",
    authDomain: "pren-evaluation.firebaseapp.com",
    projectId: "pren-evaluation",
    storageBucket: "pren-evaluation.appspot.com",
    messagingSenderId: "809944408576",
    appId: "1:809944408576:web:b29675f5babcb0a82f2ee5"
  };


const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
export { db };