import { FC } from "react";
import styles from "./RunDetail.module.css";
import { OBJViewer } from "./OBJViewer";


interface RunMapProps {
  mapData: any;
}

export const RunMap: FC<RunMapProps> = ({ mapData }) => {
  // Render the map using mapData.
  // You can use a library like react-leaflet or any other mapping library of your choice.
  // For now, let's just display a placeholder div.

  return (
    <div className={`box ${styles["map-container"]}`}>
      <h2 className="subtitle">Map</h2>
      <OBJViewer mtlurl={window.location.origin + "/map.mtl"} objurl={window.location.origin + "/map.obj"} />
    </div>
  );
};
