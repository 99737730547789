import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


export function TrashChart({cigaretteCount, petCount, corkCount}: {cigaretteCount: number, petCount: number, corkCount: number}) {
    const data = {
        labels: ['Cigarette', 'Pet', 'Cork'],
        datasets: [
          {
            label: '# of Litters',
            data: [cigaretteCount, petCount, corkCount],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };
    const options: any = {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.label || '';
                const value = context.parsed || 0;
                return `${label}: ${value}`;
              },
            },
          },
          legend: {
            labels: {
              generateLabels: function (chart: any) {
                const data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map((label: any, i: any) => {
                    const meta = chart.getDatasetMeta(0);
                    const ds = data.datasets[0];
                    const count = ds.data[i];
                    return {
                      text: `${label}: ${count}`,
                      fillStyle: ds.backgroundColor[i],
                      strokeStyle: ds.borderColor[i],
                      lineWidth: 2,
                      hidden: isNaN(count) || meta.data[i].hidden,
                      index: i,
                    };
                  });
                }
                return [];
              },
            },
          },
        },
      };
      
  return (
    <div className="box">
        <h2 className="subtitle">Amount of Litterings</h2>
        <Pie data={data} options={options}/>
    </div> 
  );
}
