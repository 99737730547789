import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { useState, useEffect, useMemo } from "react";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { DDSLoader } from "three-stdlib";

THREE.DefaultLoadingManager.addHandler(/\.dds$/i, new DDSLoader());

type SceneProps = {
  mtlurl: string;
  objurl: string;
};

const Scene = ({ obj } : { obj: any}) => {
  if (obj) {
    obj.rotation.x = -Math.PI / 2.5;
  }

  return <primitive object={obj} scale={0.4} />;
};


function OBJViewer({ mtlurl, objurl }: SceneProps) {
  const [loadedObj, setLoadedObj] = useState<THREE.Object3D>();
  const loader : Worker = useMemo(
    () => new Worker(new URL("../../workers/objLoaderWorker.ts", import.meta.url)),
    []
  );

  useEffect(() => {
    console.log({ mtlurl, objurl })
    loader.postMessage({ mtlurl, objurl });
    loader.onmessage = (event) => {
      if (event.data.obj) {
        console.time('PARSING OBJ')
        const object = new THREE.ObjectLoader().parse(event.data.obj);
        setLoadedObj(object);
        console.timeEnd('PARSING OBJ')
      } else if (event.data.error) {
        console.error(event.data.error);
      }
    };
  }, [loader, mtlurl, objurl]);

  return (
    <div
      className="App"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <>
        { loadedObj === undefined ? (
        <div className="column has-text-centered">
          <p className="title is-3">Loading...</p>
        </div>) : (
          <Canvas>
            <Scene obj={loadedObj} />
            <ambientLight intensity={0.5} />
            <directionalLight position={[2.5, 8, 5]} intensity={1} />
            <PerspectiveCamera makeDefault position={[0, 600, -300]} />
            <OrbitControls />
          </Canvas>) 
        }
      </>
    </div>
  );
}

export { OBJViewer }
