import { FC } from "react";
import { RunDetail } from "./RunDetail";
import { RunDocument } from "../../types/Run";

export const SampleRunDetail: FC = () => {
    const sampleRunDocument: RunDocument = {
        start: new Date('2023-03-17T08:00:00'),
        end: new Date('2023-03-17T08:30:00'),
        petCount: 7,
        corkCount: 4,
        cigaretteCount: 5,
        performance: [
          { timestamp: new Date('2023-03-17T08:00:00'), wattage: 100 },
          { timestamp: new Date('2023-03-17T08:05:00'), wattage: 120 },
          { timestamp: new Date('2023-03-17T08:10:00'), wattage: 110 },
          { timestamp: new Date('2023-03-17T08:15:00'), wattage: 150 },
          { timestamp: new Date('2023-03-17T08:20:00'), wattage: 130 },
          { timestamp: new Date('2023-03-17T08:25:00'), wattage: 160 },
          { timestamp: new Date('2023-03-17T08:30:00'), wattage: 140 },
        ],
        logs: [
          {
            message: 'Starting run',
            timestamp: new Date('2023-03-17T08:00:00'),
            relativeTime: "0:00",
            type: 'INFO',
          },
          {
            message: 'Found 1 pet',
            timestamp: new Date('2023-03-17T08:00:03'),
            relativeTime: "0:03",
            type: 'SUCCESS',
          },
          {
            message: 'Unable to identify anything',
            timestamp: new Date('2023-03-17T08:00:05'),
            relativeTime: "0:05",
            type: 'WARNING',
          },
          {
            message: 'Failed to complete',
            timestamp: new Date('2023-03-17T08:30:00'),
            relativeTime: "0:30",
            type: 'ERROR',
          }
        ],
        deviceStatus: 'Stopped',
      };

      
    return (
        <RunDetail runDocument={sampleRunDocument} />
    )
}