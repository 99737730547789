import { FC, useState } from "react";
import { Log } from "../../types/Run";
import "bulma/css/bulma.min.css";
import "./LogMessages.module.css"


interface LogMessagesProps {
  logs: Log[];
}

export const LogMessages: FC<LogMessagesProps> = ({ logs }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggle() {
    setIsExpanded(!isExpanded);
  }

  const logMessagesStyle = {
    maxHeight: isExpanded ? "1000px" : "0",
    overflow: "hidden",
    transition: "max-height 0.5s ease-in-out",
  };

  const iconStyle = {
    transform: isExpanded ? "rotate(0deg)" : "rotate(180deg)",
    transition: "transform 0.3s ease",
  }

  return (
    <div className="box">
      <div onClick={toggle} className="log-header" style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "24px"}}>
        <h2 className="subtitle" style={{marginBottom: 0}}>Logs</h2>
        <i className="fas fa-angle-up" style={iconStyle}></i>
      </div>

      <div id="log-messages" className="container" style={logMessagesStyle}>
        {logs && logs.map((log, index) => {
          var type;
          switch (log.type) {
            case "INFO":
              type = "is-info";
              break;
            case "WARNING":
              type = "is-warning";
              break;
            case "ERROR":
              type = "is-danger";
              break;
            case "SUCCESS":
              type = "is-success";
              break;
            default:
              type = "is-info";
          }

          var className = "message " + type;
          return (
            <article className={className}>
              <div className="message-header">
                <p>{log.type} {log.relativeTime}</p>
              </div>
              <div className="message-body">
                {log.message}
              </div>
            </article>
          )})}
      </div>
    </div>
  )
};
