import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { RunDetailPage } from './components/runDetail/RunDetailPage';
import { RunListPage } from './components/runList/RunListPage';
import 'bulma/css/bulma.min.css';
import "./spinner.scss";
import { SampleRunDetail } from './components/runDetail/SampleRunDetail';


ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<RunDetailPage />} />
      <Route path="/:id" element={<RunDetailPage />} />
      <Route path="/runs" element={<RunListPage />} />
      <Route path="/sample" element={<SampleRunDetail />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
