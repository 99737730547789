import { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { limit, where } from "@firebase/firestore";
import { collection, doc, onSnapshot, query, orderBy } from '@firebase/firestore';
import { RunDocument } from "../../types/Run";
import { RunDetail } from "./RunDetail";

export const RunDetailPage: FC = () => {
    const { id } = useParams();
    const [run, setRun] = useState<RunDocument | null>(null);

    useEffect(() => {
        const runsCollection = collection(db, 'runs');
        let runsQuery = query(runsCollection, orderBy('start', 'desc'), limit(1));

        if (id) {
            const runDoc = doc(runsCollection, id);
            runsQuery = query(runsCollection, where('__name__', '==', runDoc.id));
        }

        const unsubscribe = onSnapshot(runsQuery, (querySnapshot) => {
        if (querySnapshot.empty) {
            setRun(null);
        } else {
            const runData = querySnapshot.docs[0].data();
            setRun({
                ...runData,
                start: runData.start.toDate(),
                end: runData.end?.toDate(),
                performance: runData.performance.map((p: any) => ({
                    ...p,
                    timestamp: p.timestamp.toDate(),
                }))
            } as RunDocument);
        }
        });

        return unsubscribe;
    }, [id]);

    if (!run) {
        return (
          <div className="container">
            <div className="has-text-centered">
              <button className="button is-loading is-large">Loading...</button>
            </div>
            <div className="has-text-centered" style={{ marginTop: "1rem" }}>
              <Link to="/runs" className="button is-info">
                View All Runs
              </Link>
            </div>
          </div>
        );
      }
    
      return (
        <>
          <RunDetail runDocument={run} />
          <div className="container has-text-centered" style={{ marginTop: "1rem" }}>
            <Link to="/runs" className="button is-info">
              View All Runs
            </Link>
          </div>
        </>
      );
};