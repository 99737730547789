import { Line } from 'react-chartjs-2';
import { Performance } from '../../types/Run';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

interface WattageChartProps {
  start: Date;
  data: Performance[];
}

export const WattageChart: React.FC<WattageChartProps> = ({ data, start }) => {
  if(data.length === 0) {
    return <div className="box">
      <h2 className="subtitle">Wattage over Time</h2>
      <p>No data available</p>
    </div>
  }

  const sorted = data.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  const chartData = {
    labels: sorted.map((performance) => {
      const timestamp = moment(performance.timestamp);
      const diff = moment(timestamp.diff(start));
      return diff.format("m[m] s[s]");
    }),
    datasets: [
      {
        label: 'Wattage',
        data: sorted.map((performance) => performance.wattage),
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        pointRadius: 3,
        tension: 0.2,
        fill: false,
      },
    ],
  };

  console.log({sorted, start})
  const average = sorted.reduce((acc, curr) => acc + curr.wattage, 0) / sorted.length;
  const end = sorted[sorted.length - 1].timestamp.getTime();
  const usage = (end - start.getTime()) / 1000 / 60 / 60 * average;

  const chartOptions = {
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Wattage (W)',
        },
        min: 0,
        beginAtZero: true,
      },
    },
  };

  return  (
      <div className="box">
        <h2 className="subtitle">Wattage over Time</h2>
        <Line data={chartData} options={chartOptions} />
        <p>Average wattage: {average.toFixed(2)} W</p>
        <p>Estimated usage: {usage.toFixed(2)} Wh</p>
      </div> 
  );
};
